import React from "react";

function Footer() {
  return (
    <div className=" bg-[#ededed] w-full bottom-0" style={{position: "relative", bottom: 0, marginTop: "auto"}}>
      <p>Copyright © 2023 DoorHan</p>
    </div>
  );
}

export default Footer;
