import React, { useState, useEffect } from "react";
import DH_API from "../../../API";
import "../../../Style/Table.css";
import {useNavigate} from "react-router-dom";
const OrderTable = ({ tableData, id }) => {
    const navigate = useNavigate();

    return (
    <div>
        <table className="table">
            <caption className="bg-[#133a8f] text-white">Orders</caption>
            <thead className="border-2">
            <tr className="border-2 text-center">
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Order Id
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    UserName
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    date
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Net Revenue
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Sale
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Customer
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Item
                </th>
                <th
                    colSpan={1}
                    rowSpan={2}
                    className="bg-[#a4a6ab] border-2 border-[#152d61] p-4"
                >
                    Details
                </th>
            </tr>
            </thead>
            <tbody>
            {tableData.map((rowData, rowIndex) => {
                return (
                    <tr
                        key={rowIndex}
                        className={`${rowIndex % 2 === 0 && "bg-[#c9cbd1]"} border-2`}
                    >
                        <td className={`w-[10px] border-2`}>
                            {rowData.orderid}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            {rowData.username}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            {rowData.date}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            {rowData.netrevenue}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            {rowData.sale}
                        </td>
                        <td className={`w-[10px] border-2`}>
                        {rowData.customer}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            {rowData.item}
                        </td>
                        <td className={`w-[10px] border-2`}>
                            <button
                                className="bg-[#133a8f] p-2 text-white rounded-md"
                                onClick={()=>{
                                    navigate('/orderDetails?id=' + id + "&" +
                                        "orderId=" + rowData.orderid.replace("-", "hyphen"));
                                }}
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    </div>
    );
};
export default OrderTable;
