import React, { useState, useEffect } from "react";
import DH_API from "../../../API";
import "../../../Style/Table.css";
const Table = ({ data, id }) => {
  const [tableData, setTableData] = useState([]);
  const [widthList, setWidthList] = useState([]);
  const [heightList, setHeightList] = useState([]);

  useEffect(() => {
    const table = [];
    const widths = [...new Set(data.map(({ width }) => width))].sort(
      (a, b) => a - b
    );
    const heights = [...new Set(data.map(({ height }) => height))].sort(
      (a, b) => a - b
    );
    setWidthList(widths);
    setHeightList(heights);

    for (let y = 0; y < heights.length; y++) {
      const rowData = [];
      for (let x = 0; x < widths.length; x++) {
        const item = data.find(
          ({ width, height }) => width === widths[x] && height === heights[y]
        );
        rowData.push(
          item ? item : { width: widths[x], height: heights[y], pricing: null }
        );
      }
      table.push(rowData);
    }
    setTableData(table);
  }, [data]);

  const addWidth = () => {
    const newTableData = tableData.map((row, i) => [
      ...row,
      { width: 0, height: heightList[i], pricing: 0},
    ]);
    setTableData(newTableData);
    setWidthList([...widthList,0]);
  };

  const addHeight = () => {
    const newTableData = Array(tableData[0].length).fill(0).map((_, index) => ({
      width: widthList[index],
      height: 0,
      pricing: 0,
    }));
    setTableData([...tableData, newTableData]);

    setHeightList([...heightList,0]);
  };

  const handlePricingChange = (e, x, y) => {
    const newTableData = tableData.map((row) => [...row]);
    const inputValue = e.target.value.trim();
    newTableData[y][x].pricing = inputValue === "" ? null : Number(inputValue);
    setTableData(newTableData);
  };

  const handleHeightListChange = (e, i) => {
    let newHeightList = [...heightList];
    const inputValue = e.target.value.trim();
    newHeightList[i] = inputValue === "" ? null : Number(inputValue);
    setHeightList(newHeightList);
    const newTableData = tableData.map((row) => [...row]);
    for (let x = 0; x < widthList.length; x++) {
      newTableData[i][x].height = Number(inputValue);
    }
    setTableData(newTableData);
  };

  const handleWidthListChange = (e, i) => {
    let newWidthList = [...widthList];
    const inputValue = e.target.value.trim();
    newWidthList[i] = inputValue === "" ? null : Number(inputValue);
    setWidthList(newWidthList);

    const newTableData = tableData.map((row) => [...row]);
    for (let x = 0; x < heightList.length; x++) {
      newTableData[x][i].width = Number(inputValue);
    }
    setTableData(newTableData);
  };

  function onSubmit() {
    DH_API.updatePricingGrill(id, tableData);
  }
  return (
    <div>
      <table className="table">
        <caption className="bg-[#133a8f] text-white">DIMENSIONS</caption>
        <thead className="border-2">
          <tr className="border-2 text-center">
            <th
              colSpan={1}
              rowSpan={2}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Height, mm
            </th>

            <th
              colSpan={10000}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Width, mm
            </th>
          </tr>
          <tr>
            {tableData[0]?.map((_, i) => (
              <th
                key={i}
                className={`w-[10px] bg-[#a4a6ab] border-2 border-[#152d61] `}
              >
                <input
                  type="number"
                  value={widthList[i]}
                  onChange={(e) => handleWidthListChange(e, i)}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, y) => {
            return (
              <tr
                key={y}
                className={`${y % 2 === 0 && "bg-[#c9cbd1]"} border-2`}
              >
                <th className="w-[10px] border-r-4">
                  <input
                    type="number"
                    value={heightList[y]}
                    onChange={(e) => handleHeightListChange(e, y)}
                  />
                </th>
                {rowData.map((cellData, x) => (
                  <td key={`${x}-${y}`} className={`w-[10px] border-2`}>
                    <input
                      type="number"
                      value={cellData.pricing}
                      onChange={(e) => handlePricingChange(e, x, y)}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-row justify-around p-10">
        <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={addHeight}
        >
          Add Height Row
        </button>
        <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={addWidth}
        >
          Add Width Column
        </button>
        <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
     
    </div>
  );
};
export default Table;
