import React, {useState, useEffect, useCallback} from "react";
import PricingTable from "../../Modules/Components/PricingGrillConfiguration/PricingTable";
import DH_API from "../../API";
import ColorAndTextureTable from "../../Modules/Components/PricingGrillConfiguration/colorAndTextureOptions";
import AccessoriesTable from "../../Modules/Components/PricingGrillConfiguration/AccessoriesComponent";
import {useLocation, useNavigate} from "react-router-dom";
import "./PricingEditView.css"


const ProductCreationForm = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = parseInt(params.get('id'),10);
  const isNew = parseInt(params.get('isNew'),10);
  const navigate = useNavigate();

  //TODO should be in constants cmon...
  const colorAndTextureBaseData = [
    {
      Name: "Stucco",
      Patterns: [
        {
          pattern: "D-Line",
          colorOpts: [
            { color: "9016", opt: 1 },
            { color: "9010", opt: 2 },
            { color: "5005", opt: 3 },
            { color: "9006", opt: 1 },
            { color: "9007", opt: 1 },
            { color: "7016-SG", opt: 1 },
            { color: "7016-DM", opt: 1 },
            { color: "6009", opt: 1 },
            { color: "3000", opt: 1 },
            { color: "8014", opt: 1 },
            { color: "9005", opt: 1 },
            { color: "7040", opt: 1 },
            { color: "Satin Grey", opt: 1 },
            { color: "Golden Oak", opt: 1 },
            { color: "Dark Oak", opt: 1 },
            { color: "Mahogany", opt: 1 },
            { color: "Wenge", opt: 1 },
            { color: "Winchester", opt: 1 },
            { color: "DB703", opt: 1 },
          ],
        }
      ]
    }
  ];
  const basePricingTableData = [
    { height: 2000, width: 2000, pricing: 100, widthindex: 0, heightindex: 0 },
    { height: 3000, width: 2000, pricing: 200, widthindex: 0, heightindex: 1 },
    { height: 4000, width: 2000, pricing: 300, widthindex: 0, heightindex: 2 },
    { height: 5000, width: 2000, pricing: 400, widthindex: 0, heightindex: 3 },
    { height: 2000, width: 3000, pricing: 200, widthindex: 1, heightindex: 0 },
    { height: 3000, width: 3000, pricing: 300, widthindex: 1, heightindex: 1 },
    { height: 4000, width: 3000, pricing: 400,  widthindex: 1, heightindex: 2 },
    { height: 5000, width: 3000, pricing: 500, widthindex: 1, heightindex: 3 },
    { height: 2000, width: 4000, pricing: 300, widthindex: 2, heightindex: 0 },
    { height: 3000, width: 4000, pricing: 400, widthindex: 2, heightindex: 1 },
    { height: 4000, width: 4000, pricing: 500, widthindex: 2, heightindex: 2 },
    { height: 5000, width: 4000, pricing: 600, widthindex: 2, heightindex: 3 },
    { height: 2000, width: 5000, pricing: 400, widthindex: 3, heightindex: 0 },
    { height: 3000, width: 5000, pricing: 500, widthindex: 3, heightindex: 1 },
    { height: 4000, width: 5000, pricing: 600, widthindex: 3, heightindex: 2 },
    { height: 5000, width: 5000, pricing: 700, widthindex: 3, heightindex: 3 }
  ];
  const accessoriesData = useState([
    {
      name: "Single-ended lock for ISD",
      article: "25074K",
      price: 48,
      category: "Category 1",
      unit: "1 set",
    }
  ]);


  const [pricingData, setPricingData] = useState(basePricingTableData);
  const [product, setProduct]=useState({});
  const [selectedModel, setSelectedModel] = useState('');

  const models=["RSD01", "RSD02", "RSD02 Hybrid", "ISD", "Roller Shutter Basic", "Roller Shutter Premium"];

  useEffect(() => {
    if(!isNew){
      const fetchData = async (id) => {
        await DH_API.getPricingGrill(id).then((p) => {
          setPricingData(p);
        });

        await DH_API.getProductById(id).then((p)=>{
          setProduct( p[0]);
        })
      }
      fetchData(id);
    }

    DH_API.getProductById(id).then((p) => {
      setProduct(p[0]);
      setSelectedModel(p[0].model)
    });

  }, []);

  const onSave = () => {
    navigate('/productInfo');
    alert("Product Saved")
  };

  const onCancel = () => {
    navigate('/productInfo');
  };

  const onHandleNameChange=(e)=>{
    setProduct({...product, name: e.target.value});
  }

  const onTensionSpringCheckBoxClicked= async (e)=>{
      setProduct({...product, istensionspring: !product.istensionspring})
      await DH_API.saveProduct(id, {...product, istensionspring: !product.istensionspring});
  }
  const onTorsionSpringCheckBoxClicked= async (e)=>{
      setProduct({...product, istorsionspring: !product.istorsionspring})
      await DH_API.saveProduct(id, {...product, istorsionspring: !product.istorsionspring});
  }
  const onSaveName=()=> {
    DH_API.saveProduct(id, product);
  }

  const [shouldSave, setSouldSave] = useState(false);

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
    setProduct({...product, model: event.target.value});
  };

  const handleModelChangeCallBack = useCallback((e) => {
    setSouldSave(true)
    handleModelChange(e);
  }, [handleModelChange]);

  useEffect(()=>{
    if(shouldSave){
    DH_API.saveProduct(id, product);
    console.log("here" + product.model)
      setSouldSave(false)
    }
  },[handleModelChangeCallBack])

  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="headerTitle">Product Creation Form</h2>
      <div style={{padding: 20}}>
        <div style={{display: "inline",padding: 10}}>
        Product Name{" "}
        <input
        className="inputField"
          value={product.name}
          onChange={(e) => onHandleNameChange(e)}
      />
      </div>
        <button
            className="bg-[#133a8f] p-2 text-white rounded-md"
            onClick={onSaveName}
        >    Save Name
        </button>
        <div>
          Model:
          <select value={selectedModel}
                  onChange={m=>{
                    handleModelChangeCallBack(m)
                  }}>
            <option value="">Select an option</option>
            {models.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
            ))}
          </select>
        </div>
      </div>
      <div className="sectionTitle">Pricing with respect to Dimensions</div>
      <PricingTable data={pricingData} id={id}/>
      <div className="sectionTitle">Colors and Texture options</div>
      <ColorAndTextureTable id={id} isNew={isNew} data={colorAndTextureBaseData } />
      <div className="checkbox-section">
        <div style={{display: "flex", flexDirection: "row", justifyContent:"center", alignItems:"center", gap: 5}}>
        <p>
        isTensionSpring
        </p>
        <input
            type="checkbox"
            checked={product.istensionspring}
            onChange={onTensionSpringCheckBoxClicked}
        />
        </div>
        <div style={{display: "flex", flexDirection: "row", justifyContent:"center", alignItems:"center", gap: 5}}>
          <p>
        isTorsionSpring
        </p>
        <input
            type="checkbox"
            checked={product.istorsionspring}
            onChange={onTorsionSpringCheckBoxClicked}
        />
      </div>
      </div>
      
      <AccessoriesTable data={accessoriesData} id={id} isNew={isNew} />
      <div style={{paddingBottom: 30}}>
      <button
        className="bg-[#133a8f] p-2 text-white rounded-md"
        onClick={onCancel}
      >
        Back
      </button>
      </div>
    </div>
  );
};

export default ProductCreationForm;
