// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionTitle {
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
} 

.checkbox-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.inputField {

    width: 300px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/routes/home/PricingEditView.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;;IAEI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf","sourcesContent":[".sectionTitle {\n    font-weight: bold;\n    font-size: 20px;\n    padding: 5px;\n} \n\n.checkbox-section {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center;\n    justify-content: center;\n}\n.inputField {\n\n    width: 300px;\n    height: 50px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
