// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    border: solid 1px;
    padding: 10px;
    align-items: center;
    margin: 20px;
    width: 250px;
}


.symbolContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
    margin-right:auto;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Components/PricingGrillConfiguration/colorandTextureOptions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;IACT,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".legend {\n    display: flex;\n    align-self: center;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 10px;\n    border: solid 1px;\n    padding: 10px;\n    align-items: center;\n    margin: 20px;\n    width: 250px;\n}\n\n\n.symbolContainer {\n    display: flex;\n    flex-direction: row;\n    gap: 5px;\n    align-items: flex-start;\n    margin-right:auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
