import {useEffect, useState} from "react";
import "../../../Style/Table.css"
import DH_API from "../../../API";
import "./colorandTextureOptions.css"
const ColorAndTextureTable= ({ data, id, isNew}) => {

    const [nameColWidth, setNameColWidth] = useState([2, 2, 2]);
    const [colorsList, setColorsList]= useState(["9016", "9010", "5005", "9006", "9007", "7016-SG", "7016-DM","6009", "3000", "8014","9005","7040", "Satin Grey", "Golden Oak", "Dark Oak", "Mahogany", "Wenge", "Winchester", "DB703"]);
    const [modelList, setModelList] = useState([]);
    const [designsList] =useState(["A-Line", "Casette", "M-Line", "D-Line", "S-Line", "V-Line", "W-Line", "No Available Design"])
    const [surfaceTypes] = useState(["Stucco", "Woodgrain", "Smooth", "Microwave", "No Available Surface Type"])

    useEffect(() => {
        if(!isNew) {
            const fetchColorTextureGrill = async () => {
                try {
                    const colorTextureGrill = await DH_API.getTextureAndColorGrill(id);
                    setModelList(colorTextureGrill[0].content);
                } catch (error) {
                    console.error('Error fetching product list:', error);
                }
            };
            fetchColorTextureGrill();
        }
        else
            setModelList(data)
        
        
    }, []);

    useEffect(()=>{
        let newNameColWidth = []
        for (let i in modelList) {
            newNameColWidth.push(modelList[i].Patterns.length)
        }
        setNameColWidth(newNameColWidth)
    }, [modelList])

    const addPattern = (col, index) => {
        let newPattern = { pattern: "D-Line", colorOpts: [] };
        colorsList.map((color) => {
            newPattern.colorOpts.push({ color: color, opt: 1 });
            return color
        });
        let newModelList = modelList.map((model) => {
            if (model.Name === col.Name) {
                return {
                    ...model,
                    Patterns: [...model.Patterns, newPattern],
                };
            } else {
                return model;
            }
        });
        const newNameColWidth = [...nameColWidth];
        newNameColWidth[index] += 1;
        setNameColWidth(newNameColWidth);
        setModelList(newModelList);
    };

    const numbersToSymbolOpt = (opt) => {
        let symbol;

        switch (opt) {
            case 1:
                symbol = '\u25CF'; // big dot
                break;
            case 2:
                symbol = '\u2714'; // check mark
                break;
            case 3:
                symbol = '\u2716'; // X
                break;
            case 4:
                symbol = ' \u2605'; // star
                break;
            case 5:
                symbol = '\u0024'; // dollar sign
                break;
            case 6:
                symbol = '\u002B'; // plus sign
                break;
        }
        return (
                {symbol}
        );
    }

    const optionsChange = (col, colorIndex, opt, optIndex) => {
        let newModelList =modelList.map((model)=>{
            if(model.Name===col.Name){
                if(model.Patterns[optIndex].colorOpts[colorIndex].opt<=5)
                    model.Patterns[optIndex].colorOpts[colorIndex].opt+=1
                else
                    model.Patterns[optIndex].colorOpts[colorIndex].opt=1
                return model;
            }
            return model;
        })
        setModelList(newModelList);
    };

    const addModel = () => {
        const newItemModel={Name:"Stucco", Patterns: [{pattern:"D-Line", colorOpts:colorsList.map((color)=>{return {color:color, opt:1}})}]}
        setModelList([...modelList, newItemModel])
    };

    const handleSubmit=()=>{
        DH_API.saveTextureAndColorGrill(id, modelList);
    }

    const handleColorChange=(color, colorIndex)=>{
        const newModelList = modelList.map(data => {
        const updatedPatterns = data.Patterns.map(pattern => {
            const updatedColorOpts = pattern.colorOpts.map((colorOpt, index) => {
                if (index === colorIndex) {
                    return { ...colorOpt, color: color };
                } else {
                    return colorOpt;
                }
            });
            return { ...pattern, colorOpts: updatedColorOpts };
        });
        return { ...data, Patterns: updatedPatterns };
    });
        setModelList(newModelList);
        const newColorsList = [...colorsList];
        newColorsList[colorIndex] =color;
        setColorsList(newColorsList)
    }


    const handleHeaderChange = (index, e) => {
        const newPatternList = [...modelList];
        newPatternList[index].Name = e.target.value;
        setModelList(newPatternList);

        const newNameColWidth = newPatternList.map((col) => col.Patterns.length);
        setNameColWidth(newNameColWidth);
    };


    const handlePatternChange = (colIndex, patternIndex, e) => {
        const newPatternList = [...modelList];
        newPatternList[colIndex].Patterns[patternIndex].pattern = e.target.value;
        setModelList(newPatternList);

        const newNameColWidth = newPatternList.map((col) => col.Patterns.length);
        setNameColWidth(newNameColWidth);
    };
    return (
    <div>
        <table className="table">
            <thead>
            <tr>
                <th rowSpan="2"></th>
                {modelList.map((col, index) => { 
                    let allowedSurfaces = []
                    surfaceTypes.forEach(surface =>{
                       
                        for (let i in modelList) {
                            if (modelList[i].Name === surface) {
                                // Not allowed surface
                                break
                            } else if (parseInt(i) === modelList.length - 1) {
                                allowedSurfaces.push(surface)
                            }
                        }
                    })
                    return(
                    <th key={col.Name} colSpan={nameColWidth[index]} style={{  border: "solid 1px", padding: 10}}>
                        <select name="surface_types" onChange={(e)=>handleHeaderChange(index, e)}>
                            {
                                surfaceTypes.map((surface)=>{
                                        if (surface === col.Name) return (
                                            <option value={surface} selected>{surface}</option>
                                        )
                                        else if (allowedSurfaces.includes(surface)) {
                                            return (
                                                <option value={surface}>{surface}</option>
                                        )
                                    }

                                    })
                            }
                            </select>
                        <div onClick={() => addPattern(col, index)} style={{width: 20, border:"solid 1px", alignSelf:"center", borderRadius: "20%", cursor: "pointer"}}>+</div>
                    </th>
                )})}
            </tr>
            <tr>
                {modelList.map((col, colIndex) => {

                    return col.Patterns.map((pattern, patternIndex) => {
                        let allowedDesigns = []
                        designsList.forEach(design =>{
                            for (let i in col.Patterns) {
                                if (col.Patterns[i].pattern === design) {
                                    // Not allowed surface
                                    break
                                } else if (parseInt(i) === col.Patterns.length - 1) {
                                    allowedDesigns.push(design)
                                }
                            }
                        })
                        return (
                        <th key={patternIndex} style={{padding: 10}}>
                            <select name="surface_types" selected={col.Name} onChange={(e) => handlePatternChange(colIndex, patternIndex, e)}>
                                {
                                    designsList.map((design)=>{
                                        if (design === pattern.pattern) return (
                                            <option value={design} selected="selected">{design}</option>
                                        )
                                        else if (allowedDesigns.includes(design)) return (
                                            <option value={design}>{design}</option>
                                        )
                                    })
                                }
                                </select>
                            {/* <input
                                type="text"
                                value={pattern.pattern}
                                onChange={(e) => handlePatternChange(colIndex, patternIndex, e)}
                            /> */}
                        </th>
                    )});
                })}
            </tr>
            </thead>
            <tbody>
            {colorsList.map((color, colorIndex) => (
                <tr key={color}>
                    <td>{color}</td>
                    {modelList.map((col, colIndex) => {
                        const columnValues = col.Patterns.flatMap(({ colorOpts }) =>
                            colorOpts
                                .filter((colorOpt) => colorOpt.color === color)
                                .map(({ opt }) => opt)
                        );
                        return columnValues.map((opt, optIndex) => {
                            return(
                            <td
                                onClick={() => optionsChange(col, colorIndex, opt, optIndex)}
                                style={{width: "50px"}}
                            >
                                <div className="symbol">{numbersToSymbolOpt(opt).symbol}</div>
                            </td>)
                        });
                    })}
                </tr>
            ))}
            </tbody>
            
        </table>
        <div className="legend">
        <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(2).symbol}</div>
                <p>No Added Cost</p>
            </div>
            <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(1).symbol}</div>
                <p>50$/m2</p>
            </div>
            <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(3).symbol}</div>
                <p>Not Available</p>
            </div>
            <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(4).symbol}</div>
                <p>10% Additional and 50$/m2</p>
            </div>
            <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(5).symbol}</div>
                <p>15% Additional and 50$/m2</p>
            </div>
            <div className="symbolContainer">
                <div className="symbol">{numbersToSymbolOpt(6).symbol}</div>
                <p>25% Additional and 50$/m2</p>
            </div>


        </div>
        <div className="flex flex-row justify-around p-10">
                <button
                    className="bg-[#133a8f] p-2 text-white rounded-md"
                    onClick={addModel}
                >
                    Add Model
                </button>
                <button
                    className="bg-[#133a8f] p-2 text-white rounded-md"
                    onClick={handleSubmit}
                >
                    Submit Color and Textures
                </button>
            </div>
        </div>
    );
};

export default ColorAndTextureTable;
