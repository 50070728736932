import React, { useState, useEffect } from "react";
import DH_API from "../../../API";
import "../../../Style/Table.css";
const AccessoriesTable = ({ data, isNew, id }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if(!isNew) {
      const fetchAccessories = async () => {
        try {
          const accessories = await DH_API.getAccessoriesGrill(id);
          setTableData(accessories);
        } catch (error) {
          console.error('Error fetching product list:', error);
        }
      };
      fetchAccessories();
    }
    else{
      setTableData(data[0]) //todo bug here i am dodging it by doing [0], very bad
    }
  }, []);

  const addHeight = () => {
    const newTableData = {
      name: "None",
      price: 0,
      article: "None",
      unit: 0,
    };
    setTableData([...tableData, newTableData]);
  };

  const handlePricingChange = (e, colIndex, targetCol) => {
    let newTableData= [...tableData];
    newTableData[colIndex][targetCol] = e.target.value.includes("%") ? parseFloat(e.target.value.replace(/%/g, ""))/100 : parseFloat(e.target.value);
    setTableData(newTableData)
  };

  const handleUnitsChange = (e, colIndex, targetCol) => {
    let newTableData= [...tableData];
    newTableData[colIndex][targetCol]=e.target.value;
    setTableData(newTableData)
  };

  const handleNameChange =(e, colIndex, targetCol)=>{
    let newTableData= [...tableData];
    newTableData[colIndex][targetCol]=e.target.value;
    setTableData(newTableData)
  }

  const handleArticleChange =(e, colIndex, targetCol)=>{
    let newTableData= [...tableData];
    newTableData[colIndex][targetCol]=e.target.value;
    setTableData(newTableData)
  }
  const handleCategoryChange =(e, colIndex, targetCol)=>{
    let newTableData= [...tableData];
    newTableData[colIndex][targetCol]=e.target.value;
    setTableData(newTableData)
  }

  function onSubmit() {
    DH_API.saveAccessoriesGrill(id, tableData);
  }
  return (
    <div>
      <table className="table">
        <caption className="bg-[#133a8f] text-white">ACCESSORIES</caption>
        <thead className="border-2">
          <tr className="border-2 text-center">
            <th
              colSpan={1}
              rowSpan={2}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Name
            </th>
            <th
              colSpan={1}
              rowSpan={2}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Price
            </th>
            <th
              colSpan={1}
              rowSpan={2}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Article #
            </th>
            <th
                colSpan={1}
                rowSpan={2}
                className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Category
            </th>
            <th
              colSpan={1}
              rowSpan={2}
              className="bg-[#a4a6ab] border-2 border-[#152d61]"
            >
              Units
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => {
            return (
              <tr
                key={rowIndex}
                className={`${rowIndex % 2 === 0 && "bg-[#c9cbd1]"} border-2`}
              >
                <td className={`w-[10px] border-2`}>
                  <input
                    type="text"
                    value={rowData.name}
                      onChange={(e) => handleNameChange(e, rowIndex, "name")}
                  />
                </td>
                <td className={`w-[10px] border-2`}>
                  <input
                    type="text"
                    value={rowData.price>=1 ? rowData.price : rowData.price*100 + "%"}
                     onChange={(e) => handlePricingChange(e, rowIndex, "price")}
                  />
                </td>
                <td className={`w-[10px] border-2`}>
                  <input
                    type="text"
                    value={rowData.article}
                      onChange={(e) => handleArticleChange(e, rowIndex, "article")}
                  />
                </td>
                <td className={`w-[10px] border-2`}>
                  <input
                      type="text"
                      value={rowData.category}
                      onChange={(e) => handleCategoryChange(e, rowIndex, "category")}
                  />
                </td>
                <td className={`w-[10px] border-2`}>
                <select name="units" id="units" value={rowData.unit}
                        onChange={(e) => handleUnitsChange(e, rowIndex, "unit")}>
                  
                <option value="1 lm">1 lm</option>
                <option value="m2">m2</option>
                <option value="1 set">1 set</option>
                <option value="1 pc">1 pc</option>
                  {/* <input
                    type="text"
                    value={rowData.unit}
                    onChange={(e) => handlePricingChange(e, rowIndex, "unit")}
                  /> */}
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-row justify-around p-10">
        <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={addHeight}
        >
          Add Product
        </button>
        <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default AccessoriesTable;
