import DH_API from "../../../API";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./AddAccountForm.css"
const AddAccountForm = () => {
    const navigate = useNavigate();
    const [displayError, setDisplayError] = useState(false)
    const [accountInfo, setAccountInfo]= useState({
        firstname:"",
        lastname:"",
        username:"",
        city: "",
        country: "",
        companyname: "",
        password: "",
        discount: 0,
        isadmin: false,
        isagent: false
});

    const [existingAcc, setExistingAcc]= useState({
        parentAccountId: 0
    })

    const onAddUnderClicked= async ()=>{
       const res= await DH_API.AddAccountUnder({...accountInfo, ...existingAcc});
       if(res.warn==="unsuccessful"){
           console.log("account creation unsuccessful")
       }
       else
           navigate("/accountConfiguration");
    }
    const onDuplicateClicked=async ()=>{
       const res= await DH_API.DuplicateAccount({...accountInfo, ...existingAcc});

        if(res.warn==="unsuccessful"){
            console.log("account creation unsuccessful")
            setDisplayError(true)
        }
        else
            navigate("/accountConfiguration");
    }

    return (
        <div>
            <h2 className="headerTitle">Add New Account</h2>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div className="inputContainer">
                        <label htmlFor="name">First Name:</label>
                        <input
                            className="inputField"
                            type="text"
                            id="name"
                            value={accountInfo.firstname}
                            onChange={(e) => setAccountInfo({...accountInfo, firstname: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="name">Last Name:</label>
                        <input
                            className="inputField"

                            type="text"
                            id="name"
                            value={accountInfo.lastname}
                            onChange={(e) => setAccountInfo({...accountInfo, lastname: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="name">User Name:</label>
                        <input
                            className="inputField"

                            type="text"
                            id="name"
                            value={accountInfo.username}
                            onChange={(e) => setAccountInfo({...accountInfo, username: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="city">City:</label>
                        <input
                            className="inputField"

                            type="text"
                            id="city"
                            value={accountInfo.city}
                            onChange={(e) => setAccountInfo({...accountInfo, city: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="country">Country:</label>
                        <input
                            className="inputField"

                            type="text"
                            id="country"
                            value={accountInfo.country}
                            onChange={(e) => setAccountInfo({...accountInfo, country: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="city">Company Name:</label>
                        <input
                            className="inputField"

                            type="text"
                            id="city"
                            value={accountInfo.companyname}
                            onChange={(e) => setAccountInfo({...accountInfo, companyname: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="city">Password:</label>
                        <input
                            className="inputField"
                            type="password"
                            id="city"
                            value={accountInfo.password}
                            onChange={(e) => setAccountInfo({...accountInfo, password: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <label htmlFor="city">Discount:</label>
                        <input
                        className="inputField"
                            type="number"
                            id="city"
                            value={accountInfo.discount}
                            onChange={(e) => setAccountInfo({...accountInfo, discount: e.target.value})}
                            required
                        />
                    </div>
                    <div className="inputContainer">
                        <p style={{paddingRight: 2}}>isAdmin</p>
                        <input
                            type="checkbox"
                            checked={accountInfo.isadmin}
                            onChange={(e)=>{setAccountInfo({...accountInfo, isadmin: !accountInfo.isadmin})}}
                        />
                    </div>
                    </div >
                    <div className="inputContainer">
                        <p style={{paddingRight: 2}}>isAgent</p>
                        <input
                            type="checkbox"
                            checked={accountInfo.isagent}
                            onChange={(e)=>{setAccountInfo({...accountInfo, isagent: !accountInfo.isagent})}}
                        />
                    </div>
                    <div >
                    <div>
                        <p style={{fontWeight: "bold", padding: 20}}>Select Existing Account to same level or add under</p>
                        <div className="inputContainer">
                            <label htmlFor="city">Parent Account Id:</label>
                            <input
                            className="inputField"
                                type="number"
                                id="parentAccId"
                                value={existingAcc.parentAccountId}
                                onChange={(e) => setExistingAcc({...existingAcc, parentAccountId: e.target.value})}
                                required
                            />
                        </div>
                    </div>
                    <div style={{padding: 20}}>
                <button className={"bg-[#133a8f] p-2 text-white rounded-md m-5"} onClick={onAddUnderClicked}>Add Under</button>
                <div>
                {displayError && <p style={{color: "red", fontSize: 20}}>An error occured.</p>}
                    <button className={"bg-[#133a8f] p-2 text-white rounded-md m-5"} onClick={()=>{navigate("/accountConfiguration")}}>Back</button>
                </div>
                </div>
                    </div>

                    

        </div>

    );
};



export default AddAccountForm;
