import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import DH_API from "../../../API";
import { UserContext } from "../../../App";

const AccountPage = () => {
    const navigate = useNavigate();
    const [account, setAccount] = useState({});
    const [user, setUser] = useContext(UserContext)
    const [isAdmin, setIsAdmin]= useState(false)

  let { id } = useParams();
  

  const manageAccounts=()=>{
      navigate('/accountConfiguration');
  }
    const onProductsBtn=()=>{
        navigate('/productInfo');

    }

  useEffect(() => {
    const getAccountInfo = async ()=>{
      const accountInfo = await DH_API.getAccountById(localStorage.getItem("AccountId"))
      setAccount(accountInfo[0])
      setUser(accountInfo[0])
    }
    getAccountInfo();

    setIsAdmin(localStorage.getItem("isAdmin")==="true");

  }, []);

  
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
      <div style={{padding: 10}}>
        <p>
          {account.firstname} {account.lastname}
        </p>
      </div>

      <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={manageAccounts}
      >
        Manage Accounts
      </button>
        {isAdmin && <button
          className="bg-[#133a8f] p-2 text-white rounded-md"
          onClick={onProductsBtn}
      >
        Products
      </button>}
    </div>
  );
};

export default AccountPage;

