import React, { useState, useEffect } from "react";
import DH_API from "../../../API";
import "../../../Style/Table.css";
import HierarchicalTable from "./HiearchyTable";

const AccountDetails = () => {
    const [displayError, setDisplayError] = useState(false)

  return (
    <div className="w-full p-10">
      <h1 className="text-xl font-bold">Account List</h1>
      <HierarchicalTable/>
      {displayError && <p style={{color: "red"}}>An error occured.</p>}
    </div>
  );
};
export default AccountDetails;
