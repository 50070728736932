import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DH_API from "../../../API";

//TODO: isolate this component, stateless function with functions passed down as props
const Employee = ({ employee, stylingId=0 }) => {
    const navigate = useNavigate();

    //TODO: move to vm
    const [isOpen, setIsOpen] = useState(false);
    const [childEmployees, setChildEmployees] = useState([]);

    const handleAccountSearch = (eId) => {
        DH_API.getAccountByHierarchy(eId)
            .then((response) => {
                setChildEmployees(response);
            })
            .catch((error) => {console.error(error)
            });
    };
    const handleToggle = (eId) => {
        handleAccountSearch(eId);
        setIsOpen(!isOpen);
    };
    const handleShowAccountDetails=(id)=>{
        navigate('/accountdetails?id='+ id)
    }
    if (!employee) return null;
    return (
        <li className={`${stylingId % 2 === 0 ? "bg-[#e3e5e8]" : "bg-white"
        } flex flex-col justify-between p-4`}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                 <span onClick={()=> handleToggle(employee.id)} style={{cursor: "pointer", paddingRight: 20, marginRight: 10}}>
                    {isOpen ? '- ' : '+ '} {'  '}
                 </span>
                <span onClick={()=>handleShowAccountDetails(employee.id)} style={{cursor: "pointer"}}>
                   {employee.id} {employee.firstname} {employee.lastname}
                </span>
            </div>
            {isOpen && childEmployees.length > 0 && (
                    <div style={{ flex: "1" }}>
                        <ul>
                            {childEmployees.map((e, idx) => {
                               return <div> {<Employee key={e.id} stylingId={idx} employee={e} />} </div>
                            })}
                        </ul>
                    </div>
            )}
        </li>
    );
};

//TODO make vm
const HierarchicalTable = () => {
    const [parentEmployee, setParentEmployee] = useState([]);
    useEffect(() => {
        DH_API.getAccountInfo()
            .then((response) => {
                setParentEmployee(response);
            })
            .catch((error) => {console.error(error)
            });
    }, []);

    if (!parentEmployee) return null;
    return (
        <ul>
            <Employee employee={parentEmployee}/>
        </ul>
    );
};

export default HierarchicalTable;
