import React, {useState, useEffect} from "react"
import "./OrderDetails.css"
import {useLocation, useNavigate} from "react-router-dom";
import DH_API from "../../API";
import builtInLeft from "../../Assets/doorModels/builtInLeft.png"
import builtInRight from "../../Assets/doorModels/builtInRight.png"
import onTheWallLeft from "../../Assets/doorModels/onTheWallLeft.png"
import onTheWallRight from "../../Assets/doorModels/onTheWallRight.png"
import High_Lift_ISD from "../../Assets/doorModels/High_Lift_ISD.png"
import High_Lift_Shaft_below_ISD from "../../Assets/doorModels/High_Lift_Shaft_below_ISD.png"
import Low_lift_for_door_RSD01 from "../../Assets/doorModels/Low_lift_for_door_RSD01.png"
import Low_lift_for_door_RSD02_Hybrid from "../../Assets/doorModels/Low_lift_for_door_RSD02_Hybrid.png"
import Low_lift_front_drum_for_door_RSD02 from "../../Assets/doorModels/Low_lift_front_drum_for_door_RSD02.png"
import Low_lift_front_drum_ISD from "../../Assets/doorModels/Low_lift_front_drum_ISD.png"
import Low_lift_front_drum_RKTN_ISD from "../../Assets/doorModels/Low_lift_front_drum_RKTN_ISD.png"

import Standard_Lift_ISD from "../../Assets/doorModels/Standard_Lift_ISD.png"
import Vertical_Lift_ISD from "../../Assets/doorModels/Vertical_Lift_ISD.png"
import Vertical_Lift_Shaft_below_ISD from "../../Assets/doorModels/Vertical_Lift_Shaft_below_ISD.png"

import dimensionsImage from "../../Assets/dimensionsImage.png"
const OrderDetails = ()=>{
    const [data, setData] = useState()

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = parseInt(params.get('id'),10);
    const orderId = params.get('orderId').replace("hyphen", "-");

    useEffect(()=>{
        //should create a route but I'm lazy
        DH_API.getOrdersByHierarchy(id).then((orders) => {
            const order = orders.find( o => o.orderid == orderId)
            console.log(order)
            setData(order.details);
        });

    }, [])

    const getDrawing=()=>{
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Low lift for door RSD01")
            return (
            <div>
                <img src={Low_lift_for_door_RSD01} alt="Lift Image" />
            </div>
        );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Low lift front drum for door RSD02")
            return (
                <div>
                    <img src={Low_lift_front_drum_for_door_RSD02} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Low lift for door RSD02 Hybrid")
            return (
                <div>
                    <img src={Low_lift_for_door_RSD02_Hybrid} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Low lift front drum ISD")
            return (
                <div>
                    <img src={Low_lift_front_drum_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Low lift front drum (RKTN) ISD")
            return (
                <div>
                    <img src={Low_lift_front_drum_RKTN_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Standard Lift ISD")
            return (
                <div>
                    <img src={Standard_Lift_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="High Lift ISD")
            return (
                <div>
                    <img src={High_Lift_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="High Lift, Shaft below ISD")
            return (
                <div>
                    <img src={High_Lift_Shaft_below_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Vertical Lift ISD")
            return (
                <div>
                    <img src={Vertical_Lift_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.liftType!=undefined &&
            data?.formData?.liftType=="Vertical Lift, Shaft below ISD")
            return (
                <div>
                    <img src={Vertical_Lift_Shaft_below_ISD} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.builtIn &&
            data?.formData?.direction=="Left")
            return (
                <div>
                    <img src={builtInLeft} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            data?.formData.builtIn &&
            data?.formData?.direction=="Right")
            return (
                <div>
                    <img src={builtInRight} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            !data?.formData.builtIn &&
            data?.formData?.direction=="Left")
            return (
                <div>
                    <img src={onTheWallLeft} alt="Lift Image" />
                </div>
            );
        if( data?.formData!=undefined &&
            !data?.formData.builtIn &&
            data?.formData?.direction=="Right")
            return (
                <div>
                    <img src={onTheWallRight} alt="Lift Image" />
                </div>
            );

        return(
            <div>
                no lift found
            </div>
        )
        //todo
        // builtInLeft
        // builtInRight
        // onTheWallLeft
        // onTheWallRight
       // return <img>
    }
    console.log(data?.formData?.measurements)
    return (
     <div>
            <div style={{display: "flex", flexDirection: "column", padding: 10, marginBottom: 20}}>
                <h2>Form Data</h2>
                <div className="formData-entry odd-entry">
                    <p className="entryTitle">First Name</p>
                    <p>{data?.formData?.firstName}</p>
                </div>
                <div className="formData-entry even-entry">
                    <p className="entryTitle">Last Name</p>
                    <p>{data?.formData?.lastName}</p>
                </div>
                <div className="formData-entry odd-entry">
                    <p className="entryTitle">Email Name</p>
                    <p>{data?.formData?.firstName}</p>
                </div>
                <div className="formData-entry even-entry">
                    <p className="entryTitle">Address</p>
                    <p>{data?.formData?.Address}</p>
                </div>
                <div className="formData-entry odd-entry">
                    <p className="entryTitle">Region</p>
                    <p>{data?.formData?.Region}</p>
                </div>
                <div className="formData-entry even-entry">
                    <p className="entryTitle">Country</p>
                    <p>{data?.formData?.Country}</p>
                </div>
                <div className="formData-entry odd-entry">
                    <p className="entryTitle">Phone Number</p>
                    <p>{data?.formData?.PhoneNumber}</p>
                </div>
                <div className="formData-entry even-entry">
                    <p className="entryTitle">Product Name</p>
                    <p>{data?.formData?.productName}</p>
                </div>
                <div className="formData-entry odd-entry">
                    <p className="entryTitle">Product ID</p>
                    <p>{data?.formData?.productId}</p>
                </div>
                <div className="formData-entry even-entry">
                    <p className="entryTitle">Coefficient</p>
                    <p>{data?.formData?.coefficient}</p>
                </div>

            </div>
            <div style={{display: "flex", flexDirection: "column", padding: 10, marginBottom: 20}}>
                <h2>Product Information</h2>
                <table className="product-table">
                    <tr>
                        <th>Entry</th>
                        <th>Value</th>
                    </tr>
                    <tr>
                        <td>L1</td>
                        <td>{data?.formData?.L1}</td>
                    </tr>
                    <tr>
                        <td>L2</td>
                        <td>{data?.formData?.L2}</td>
                    </tr>
                    <tr>
                        <td>H1</td>
                        <td>{data?.formData?.H1}</td>
                    </tr>
                    <tr>
                        <td>H2</td>
                        <td>{data?.formData?.H2}</td>
                    </tr>
                    <tr>
                        <td>b1</td>
                        <td>{data?.formData?.b1}</td>
                    </tr>
                    <tr>
                        <td>b2</td>
                        <td>{data?.formData?.b2}</td>
                    </tr>
                    <tr>
                        <td>h</td>
                        <td>{data?.formData?.h}</td>
                    </tr>
                    <tr>
                        <td>Lt</td>
                        <td>{data?.formData?.lt}</td>
                    </tr>
                    <tr>
                        <td>Surface</td>
                        <td>{data?.formData?.Surface}</td>
                    </tr>
                    <tr>
                        <td>Color</td>
                        <td>{data?.formData?.Color}</td>
                    </tr>
                </table>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
                    <img src={dimensionsImage} style={{ width: '700px', height: 'auto' }} alt="Lift Image" />

                </div>

            </div>

            <div style={{display: "flex", flexDirection: "column", padding: 10, marginBottom: 20}}>
             <h2>Lift Information</h2>
             <table className="product-table">
                 <tr>
                     <th>Entry</th>
                     <th>Value</th>
                 </tr>
                 {data?.formData?.measurements != undefined &&
                     Object.entries(data?.formData?.measurements[0]).map(([key, value]) => (
                     <tr>
                         <td>{key}</td>
                         <td>{value}</td>
                     </tr>
                     ))
                 }
             </table>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {getDrawing()}
                </div>
         </div>
            <div style={{display: "flex", flexDirection: "column", padding: 10, marginBottom: 20}}>
                <h3>Additional Note</h3>
                <p style={{padding: 5, border: "solid 1px", marginLeft: "30%", marginRight: "30%", textAlign: "left", color: data?.formData?.additionalNote!=undefined ? "black" : "gray"}}>
                    {data?.formData?.additionalNote!=undefined ?
                        data?.formData?.additionalNote : "No Additional Note"}</p>
            </div>
            <div style={{display: "flex", flexDirection: "column", padding: 10, marginBottom: 20}}>
                <h2>Price Breakdown</h2>
                <div style={{alignSelf: "center", padding: 20}}>
                    <table className="priceBreakdown-table">
                        <thead className="border-2">
                        <tr>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Type</th>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Name</th>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Net Price</th>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Original Price</th>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Amount</th>
                            <th className="bg-[#a4a6ab] border-2 border-[#152d61] p-4">Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.itemsList?.map((rowData, rowIndex) => {
                            if (rowData.type !== "subTotalPrice" && rowData.type !== "totalPrice" && rowData.type !== "netRev")
                                return (
                                    <tr
                                        key={rowIndex}
                                        className={`${rowIndex % 2 === 0 && "bg-[#c9cbd1]"} border-2`}
                                    >
                                        <td className={`w-[10px] border-2 p-5 `}>
                                            {rowData?.type || "-"}
                                        </td>
                                        <td className={`w-[10px] border-2 p-5`}>
                                            {rowData?.name || "-"}
                                        </td>
                                        <td className={`w-[10px] border-2 p-5`}>
                                            {rowData?.netPrice || "-"}
                                        </td>
                                        <td className={`w-[10px] border-2 p-5`}>
                                            {rowData?.originalPrice || "-"}
                                        </td>
                                        <td className={`w-[10px] border-2 p-5`}>
                                            {rowData?.qty || "-"}
                                        </td>
                                        <td className={`w-[10px] border-2 p-5`}>
                                            {rowData?.price}
                                        </td>

                                    </tr>
                                );
                        })}
                        </tbody>
                    </table>
                    <div style={{display: "flex", flexDirection :"column", float : "right"}}>

                        <div className="bottomPrice-element">
                            <p style={{flex: 1}}>Sub Total</p>
                            <p>{data?.itemsList?.map((rowData, rowIndex) => {
                                    if (rowData.type === "subTotalPrice")
                                        return (
                                            <p>{rowData.price}</p>
                                        )
                                }
                            )}</p>
                        </div>
                        <div className="bottomPrice-element">
                            <p style={{flex: 1}}>Total Price</p>
                            <p>{data?.itemsList?.map((rowData, rowIndex) => {
                                    if (rowData.type === "totalPrice")
                                        return (
                                            <p>{rowData?.price}</p>
                                        )
                                }
                            )}</p>
                        </div>
                        <hr></hr>
                        <div className="bottomPrice-element">
                            <p style={{flex: 1, fontWeight: "bold"}}>Net Revenue</p>
                            <p>{data?.itemsList?.map((rowData, rowIndex) => {
                                    if (rowData.type === "netRev")
                                        return (
                                            <p>{rowData?.price}</p>
                                        )
                                }
                            )}</p>
                        </div>
                    </div>
                </div>
                <div style={{padding: 20}}>
                    <button
                        className="bg-[#133a8f] p-2 text-white rounded-md"
                        onClick={()=>{navigate("/accountdetails?id=" + id)}}>
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    )
}



export default OrderDetails