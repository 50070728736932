import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import ProductCreationForm from "./routes/home/PricingEditView";
import AccountConfiguration from "./routes/Account/AccountConfigPage/AccountConfiguaration";
import NavBar from "./Modules/Components/Styling/NavBar";
import Footer from "./Modules/Components/Styling/Footer";
import LoginPage from "./routes/Auth/LoginForm";
import AccountPage from "./Modules/Components/AccountConfiguration/AccountPage";
import ProductInfoView from "./routes/Products/productInformation"
import AccountDetails from "./routes/Account/AccountDetails/AccountDetails";
import AddAccountForm from "./routes/Account/AccountConfigPage/AddAccountForm";
import OrderDetails from "./routes/Order/OrderDetails";
import { useState, createContext } from "react";
import SupportView from "./routes/Support/supportView";
export const UserContext = createContext({})

const ProtectedRoute = ({ user, children }) => {

  if (!localStorage.getItem("AccountId") || !localStorage.getItem("DHToken") ) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const ProtectedRouteForAgent = ({ user, children })=>{

  if (!localStorage.getItem("AccountId") || !localStorage.getItem("DHToken" )|| localStorage.getItem("isAdmin" )!=="true") {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function App() {

  const [user, setUser] = useState({jwt: localStorage.getItem("DHToken"), id: localStorage.getItem("AccountId"), isAdmin: localStorage.getItem("isAdmin")})

  return (
    <UserContext.Provider value={[user, setUser]}>
    <div className="App min-h-[100vh]">
      <NavBar />
        <Routes>
          <Route exact path="/createProduct" element={
          <ProtectedRouteForAgent user={user}>
            <ProductCreationForm />
          </ProtectedRouteForAgent>
          }/>
          <Route exact path="/productInfo" element={
           <ProtectedRouteForAgent user={user}><ProductInfoView/>
           </ProtectedRouteForAgent>}/>
          <Route exact path="/login" element={<LoginPage />}/>
          <Route exact path="/orderDetails" element={<OrderDetails />} />
          <Route exact path="/accountConfiguration" element={<ProtectedRoute user={user}><AccountConfiguration/></ProtectedRoute>}/>
          <Route exact path="/account" element={<ProtectedRoute user={user}><AccountPage /></ProtectedRoute>} />
          <Route exact path="/addaccount" element={<ProtectedRoute user={user}><AddAccountForm /></ProtectedRoute>} />
          <Route exact path="/accountdetails" element={<ProtectedRoute user={user}><AccountDetails/></ProtectedRoute>}/>
          <Route exact path="/support" element={<SupportView/>}/>
          <Route path="*" element={ <Navigate to="/account" /> } />
        </Routes>

      <Footer />
    </div>
    </UserContext.Provider>
  );
}

export default App;
