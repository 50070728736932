class DH_API {
  //static baseURL = "http://localhost:5001"; //local
  //static baseURL = "https://dh-backend-server.onrender.com"
  static baseURL = "https://dh-backend.doorhan.com" //prod
  static call(method = "GET", route = "", data = null, options = null) {
    let headers = {
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    if (options) Object.assign(headers, options);

    const token = localStorage.getItem("DHToken");
    if (token) headers.Authorization =token;
    let body = null;
    if (data) body = JSON.stringify(data);

    return fetch(`${this.baseURL}/${route}`, {
      method,
      headers,
      body,
    })
      .then((res) => {
        if (!(res.status >= 200 && res.status < 300)) {
          const contentType = res.headers.get("content-type");
          if (contentType.startsWith("application/json;"))
            return res.json().then((err) => {
              throw new Error(err);
            });
          else if (contentType.startsWith("text/plain;"))
            return res.text().then((text) => {
              throw new Error(text);
            });
        }
        return res;
      })
      .then((res) => (res.status === 200 ? res.json() : res));
  }
  //products call
  static getProductList() {
    return DH_API.call("GET", "products/getproductlist");
  }
  static getProductById(productId) {
    return DH_API.call("GET", "products/getProductById/"+ productId);
  }

  static createProduct() {
    return DH_API.call("POST", "products/createProduct");
  }

  static softDeleteProduct(productId) {
    return DH_API.call("PUT", "products/softDeleteProduct/"+productId);
  }

  static updatePricingGrill(productID, payload) {
    return DH_API.call("PUT", "pricingGrill/updatePricingGrill/" + productID, payload);
  }
  static getPricingGrill(productID) {
    return DH_API.call("GET", "pricingGrill/getPricingGrill/" + productID);
  }

  static saveTextureAndColorGrill(productId, payload){
    return DH_API.call("POST", "products/saveTextureAndColorGrill/"+ productId, payload)
  }
  static getTextureAndColorGrill(productId){
    return DH_API.call("GET", "products/getTextureAndColorGrill/"+ productId)
  }

  static saveAccessoriesGrill(productId, payload){
    return DH_API.call("POST", "products/saveAccessoriesGrill/"+ productId, payload)
  }

  static AddAccountUnder(payload){
    return DH_API.call("POST", "accounts/addaccountunder", payload)
  }

  static DuplicateAccount(payload){
    return DH_API.call("POST", "accounts/duplicate", payload)
  }
  static getAccessoriesGrill(productId){
    return DH_API.call("GET", "products/getAccessoriesGrill/"+ productId)
  }
  static saveProduct(productId, product){
    return DH_API.call("PUT", "products/saveProduct/"+ productId, product)
  }

  //orders calls
  static getOrdersByHierarchyAndDate(productId, from, to){
    return DH_API.call("GET", `orders/getOrdersByHierarchy/${productId}?from=${from}&to=${to}`)
  }


  //account calls
  static getAccountByHierarchy(accountId) {
    return DH_API.call("GET", `accounts/getAccountByHierarchy/${accountId}`);
  }
  static getAccountInfo() {
    return DH_API.call("GET", `accounts/getAccountInfo`);
  }

  static getAccountById(id) {
    return DH_API.call("GET", "accounts/getAccountById/" + id);
  }

  static saveAccountInfo(id, accountInfo) {
    return DH_API.call("PUT", "accounts/saveAccountInfo/" + id, accountInfo);
  }

  //auth calls
  static login(username, hashedPwd) {
    return DH_API.call("POST", "auth/login", {
      username: username,
      password: hashedPwd
    });
  }
}
export default DH_API;
