// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.formData-entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-right: 20%;
    margin-left: 20%;
}

.odd-entry {
    background-color: rgb(188, 198, 230);
}

.entryTitle {
    font-weight: bold;
}
.product-table {
    border-collapse: collapse;
    border: 1px solid #dddddd;
    margin-right: 30%;
    margin-left: 30%;
}

tr:nth-child(even) {
    background-color: #dddddd;
  }
.product-table > tr {
padding: 8px;
}
.product-table > tr > td {
    border: 1px solid #cec7c7;
    }
.product-table > tr > th {
    border: 1px solid #cec7c7;
    }

.bottomPrice-element {
    padding: 20px;
    float: right;
    gap: 50px;
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/routes/Order/OrderDetails.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;EAC3B;AACF;AACA,YAAY;AACZ;AACA;IACI,yBAAyB;IACzB;AACJ;IACI,yBAAyB;IACzB;;AAEJ;IACI,aAAa;IACb,YAAY;IACZ,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n.formData-entry {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 10px;\n    margin-right: 20%;\n    margin-left: 20%;\n}\n\n.odd-entry {\n    background-color: rgb(188, 198, 230);\n}\n\n.entryTitle {\n    font-weight: bold;\n}\n.product-table {\n    border-collapse: collapse;\n    border: 1px solid #dddddd;\n    margin-right: 30%;\n    margin-left: 30%;\n}\n\ntr:nth-child(even) {\n    background-color: #dddddd;\n  }\n.product-table > tr {\npadding: 8px;\n}\n.product-table > tr > td {\n    border: 1px solid #cec7c7;\n    }\n.product-table > tr > th {\n    border: 1px solid #cec7c7;\n    }\n\n.bottomPrice-element {\n    padding: 20px;\n    float: right;\n    gap: 50px;\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
