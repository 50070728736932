// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined-basic-login {
  width: 50vw;
  align-self: center;
}

.formContainer {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 30px;
  border-radius: 5px;
  align-self: center;
  padding: 40px;
  padding-top: 30px;
  min-width: 350px;
}

.err-msg {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/Style/FormStyling.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,2EAA2E;EAC3E,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".formContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.outlined-basic-login {\n  width: 50vw;\n  align-self: center;\n}\n\n.formContainer {\n  display: flex;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);\n  margin: 30px;\n  border-radius: 5px;\n  align-self: center;\n  padding: 40px;\n  padding-top: 30px;\n  min-width: 350px;\n}\n\n.err-msg {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
