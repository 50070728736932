// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputField {

  width: 300px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

label {
    width: 300px;
}

.inputContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.headerTitle {
    font-weight: bold;
    font-size: 30px;   
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Account/AccountConfigPage/AddAccountForm.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".inputField {\n\n  width: 300px;\n  height: 50px;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  padding: 10px;\n}\n\nlabel {\n    width: 300px;\n}\n\n.inputContainer {\n    padding: 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n.headerTitle {\n    font-weight: bold;\n    font-size: 30px;   \n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
