import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import OrderTableComponent from "../../../Modules/Components/Orders/OrderTableComponent";
import DH_API from "../../../API";
import AccountDetailsForm from "./AccountDetailsForm";
import Calendar from 'react-calendar';

const AccountDetails = () =>{ //todo make vm
    const navigate = useNavigate()
const [personalOrders, setPersonalOrders]=useState([]);
const [dealerOrders, setDealerOrders]=useState([]);
const [allOrders, setAllOrders]= useState([]);

const location = useLocation();
const params = new URLSearchParams(location.search);

const id = parseInt(params.get('id'),10);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const handleFromDateChange = date => {
        DH_API.getOrdersByHierarchyAndDate(id, date, toDate).then((orders) => {
            setAllOrders(orders);
        });
        setFromDate(date);
    };

    const handleToDateChange = date => {
        console.log(date)
        DH_API.getOrdersByHierarchyAndDate(id, fromDate, date).then((orders) => {
            setAllOrders(orders);
        });

        setToDate(date);
    };

    useEffect(() => {
        DH_API.getOrdersByHierarchyAndDate(id, fromDate, toDate).then((orders) => {
            setAllOrders(orders);
        });
    }, []);

    useEffect(() => {
        if (allOrders.length > 0) {
            setPersonalOrders(allOrders.filter((order) => parseInt(order.accountid) === id));
            setDealerOrders(allOrders.filter((order) => parseInt(order.accountid) !== id));
        }

    }, [allOrders]);

    return (
    <div style={{paddingBottom: 30, display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

        <div>
            <AccountDetailsForm accountId={id}/>
        </div>
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <div>
                    <h2>From</h2>
                    <Calendar
                        onChange={handleFromDateChange}
                        value={fromDate}
                    />
                    <p>Selected Date: {fromDate.toDateString()}</p>
                </div>
                <div>
                    <h2>To</h2>
                    <Calendar
                        onChange={handleToDateChange}
                        value={toDate}
                    />
                    <p>Selected Date: {toDate.toDateString()}</p>
                </div>
            </div>
        </div>
        <div>
        <h3>Personal Orders</h3>
        <OrderTableComponent tableData={personalOrders} id={id}/>
        </div>
        <div>
        <h3>Dealers Orders</h3>
        <OrderTableComponent tableData={dealerOrders} id={id}/>
        </div>


        <div className="p-10">
            <a className="bg-[#133a8f] p-2 text-white rounded-md"
               style={{cursor: 'pointer'}}
               download="orders.json"
               href={`data:application/json,${JSON.stringify(allOrders)}`}
            >
                Generate JSON Orders
            </a>
        </div>
        <div style={{paddingTop: 30}}>
        <button
                    className="bg-[#133a8f] p-2 text-white rounded-md"
                    onClick={()=>{
                        navigate("/accountConfiguration")
                    }}
                >
                    Back
                </button>
                </div>
    </div>);
};



export default AccountDetails;
