// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
label {
    width: 300px;
}

.inputField {
    width: 200px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }

h2 {
    padding: 10px;
    font-size: 30px;
    font-weight: bold;
}

h3 {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/routes/Account/AccountDetails/AccountDetailsForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf;;AAEF;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".inputContainer {\n    padding: 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\nlabel {\n    width: 300px;\n}\n\n.inputField {\n    width: 200px;\n    height: 40px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    padding: 10px;\n  }\n\nh2 {\n    padding: 10px;\n    font-size: 30px;\n    font-weight: bold;\n}\n\nh3 {\n    padding: 20px;\n    font-size: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
