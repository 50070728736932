import React, {useEffect, useState} from 'react';
import DH_API from "../../../API";
import "./AccountDetailsForm.css"
import ColorAndTextureTable from '../../../Modules/Components/PricingGrillConfiguration/colorAndTextureOptions';
const AccountDetailsForm = ({accountId}) => {

const [displayError, setDisplayError] = useState(true)

useEffect(()=>{
    DH_API.getAccountById(accountId).then((acc) => {
        setAccountInfo(acc[0]);
    }).catch(err=>{
        console.error(err)
        setDisplayError(ColorAndTextureTable)
    })
},[]);

    const [accountInfo, setAccountInfo]= useState({});

    const onSaveAccountInfo= ()=>{
        DH_API.saveAccountInfo(accountId, accountInfo);
    }

    return (
        <div>
            <h2>Account Information</h2>
            <div className="inputContainer">
            <label htmlFor="name">First Name:</label>
            <input
            className='inputField'
                type="text"
                id="name"
                value={accountInfo.firstname}
                onChange={(e) => setAccountInfo({...accountInfo, firstname: e.target.value})}
                required
            />
            </div>
            <div className="inputContainer">
                <label htmlFor="name">Last Name:</label>
                <input
                className='inputField'
                    type="text"
                    id="name"
                    value={accountInfo.lastname}
                    onChange={(e) => setAccountInfo({...accountInfo, lastname: e.target.value})}
                    required
                />
            </div >
            <div className="inputContainer">
                <label htmlFor="name">User Name:</label>
                <input
                className='inputField'
                    type="text"
                    id="name"
                    value={accountInfo.username}
                    onChange={(e) => setAccountInfo({...accountInfo, username: e.target.value})}
                    required
                />
            </div>
            <div className="inputContainer">
            <label htmlFor="city">City:</label>
            <input
            className='inputField'
                type="text"
                id="city"
                value={accountInfo.city}
                onChange={(e) => setAccountInfo({...accountInfo, city: e.target.value})}
                required
            />
            </div>
            <div className="inputContainer">
                <label htmlFor="country">Country:</label>
                <input
                className='inputField'
                    type="text"
                    id="country"
                    value={accountInfo.country}
                    onChange={(e) => setAccountInfo({...accountInfo, country: e.target.value})}
                    required
                />
            </div>
            <div className="inputContainer">
                <label htmlFor="city">Company Name:</label>
                <input
                className='inputField'
                    type="text"
                    id="city"
                    value={accountInfo.companyname}
                    onChange={(e) => setAccountInfo({...accountInfo, companyname: e.target.value})}
                    required
                />
            </div>
            <div className="inputContainer">
                <label htmlFor="city">Password:</label>
                <input
                className='inputField'
                    type="password"
                    id="city"
                    value={accountInfo.password}
                    onChange={(e) => setAccountInfo({...accountInfo, password: e.target.value})}
                    required
                />
            </div>
            <div className="inputContainer">
                <label htmlFor="city">Discount:</label>
                <input
                className='inputField'
                    type="text"
                    id="city"
                    value={accountInfo.discount}
                    onChange={(e) => setAccountInfo({...accountInfo, discount: e.target.value})}
                    required
                />
            </div>
            <div className="inputContainer">
                <p style={{paddingRight: 2}}>isAdmin</p>
                <input
                    type="checkbox"
                    checked={accountInfo.isadmin}
                    onChange={(e)=>{setAccountInfo({...accountInfo, isadmin: !accountInfo.isadmin})}}
                />
            </div>
            <div className="inputContainer">
                <p style={{paddingRight: 2}}>isAgent</p>
                <input
                    type="checkbox"
                    checked={accountInfo.isagent}
                    onChange={(e)=>{setAccountInfo({...accountInfo, isagent: !accountInfo.isagent})}}
                />
            </div>
            <div style={{padding: 10}}>
                <button
                    className="bg-[#133a8f] p-2 text-white rounded-md"
                    onClick={onSaveAccountInfo}
                >
                    Submit
                </button>
            </div>
            {displayError && <p style={{color: "red",}}>An error occured.</p>}
        </div>
    );
};

export default AccountDetailsForm
