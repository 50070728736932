import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import DH_API from "../../API";

const ProductInfoView = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();


    const onEditProdBtn=(id)=>{
        navigate('/createProduct?id='+ id);
    }
    const onDeleteProdBtn= async (id)=>{
        await DH_API.softDeleteProduct(id);
        fetchProductList();
    }
    const onCreateProdBtn= async ()=>{
        const newId= await DH_API.createProduct(); //creates it and returns the new id
        navigate('/createProduct?id='+ newId.id+"&"+"isNew="+1);
    }
    const fetchProductList = async () => {
        try {
            const productList = await DH_API.getProductList();
            setProducts(productList);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };
    useEffect(() => {

        fetchProductList();
    }, []);

    return (
        <div>
            <div>
                <ul>
                    {products.map((product, idx) => {
                        if(product.name!=="DELETEDPRODUCT"){
                        return (
                             <li
                                className={`${
                                    idx % 2 === 0 && "bg-[#e3e5e8]"
                                } flex flex-row justify-between p-4`}
                                key={product.id}
                            >
                                <div>
                                    {product.id+" "}
                                    {product.name}
                                </div>
                                <button
                                    className="bg-[#133a8f] p-2 text-white rounded-md"
                                    onClick={()=>onEditProdBtn(product.id)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-[#133a8f] p-2 text-white rounded-md"
                                    onClick={()=>onDeleteProdBtn(product.id)}
                                >
                                    Delete
                                </button>
                            </li>
                        );}
                    })}
                </ul>
                <button
                    className="bg-[#133a8f] p-2 text-white rounded-md mt-10"
                    onClick={onCreateProdBtn}
                >
                    Create Product
                </button>
            </div>
        </div>
    );
};




export default ProductInfoView;
