import { Link } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import Logo from "../../../logo.png";
import { UserContext } from "../../../App";

import { useLayoutEffect, useState, useEffect, useContext } from "react";
function useWindowWidth() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  return width;
}
const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const width = useWindowWidth();
  const [user, setUser] = useContext(UserContext)

  const logoutProcedure = ()=>{
    localStorage.removeItem("DHToken")
    localStorage.removeItem("AccountId")
    setUser({})
  }

  useEffect(() => {
    if (window.innerWidth > 750 && drawerOpen) {
      setDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);
  const CompressedNavBar = () => {
    return (
      <nav className="bg-[#ebf0fa] items-center justify-between p-4">
        <ul className="flex flex-col">
          
          <Link to="/login">
          <li className="px-5 py-5 hover:bg-slate-200">
            <a className=" text-black hover:rounded-lg hover:bg-slate-200 px-5 py-5   hover:text-slate-900 " onClick={()=>logoutProcedure()}>
              Logout
            </a>
          </li>
          </Link>
        </ul>
      </nav>
    );
  };
  if (width >= 750) {
    return (
      <nav className=" flex items-center justify-between p-4">
        <Link to="/account">
        <img src={Logo} className="h-10" alt="logo" />
        </Link>
        <ul className="flex pr-5">
          
          <Link to="/login" >
          <li>
            <a className=" text-black hover:rounded-lg hover:bg-slate-200 px-5 py-5   hover:text-slate-900 " onClick={()=>logoutProcedure()}>
              Logout
            </a>
          </li>
          </Link>
        </ul>
      </nav>
    );
  } else {
    return (
      <div className="pb-10 bg-[#ebf0fa]">
        <div className="flex justify-between items-center">
        <Link to="/account">
          <img src={Logo} className="h-10" alt="logo" />
          </Link>
          <div
            className="hover:bg-slate-100 hover:rounded-lg cursor-pointer mt-4 p-3"
            onClick={() => {
              setDrawerOpen(!drawerOpen);
            }}
          >
            <ReorderIcon style={{ color: "black" }} />
          </div>
        </div>
        {drawerOpen && <CompressedNavBar />}
      </div>
    );
  }
};

export default NavBar;
