import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import DH_API from "../../API";

const SupportView = () => {

    const [text, setText] = useState('');
    const [name, setName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleTextChange = (event) => {
        setText(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleSubmit = ()=> {
        setName("");
        setText("");
        setIsSubmitted(true);

    }


    return (
        <div>
            {!isSubmitted && <div>
                <div>
                    Please contact the tech team at ericlu3141592@gmail.com
                </div>
                <div>
                    or fill the form bellow, we will get back to you shortly
                </div>
                    <div>
                        <div>
                            <h2>Please enter you username</h2>
                            <input
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                placeholder="Enter your username"
                            />
                        </div>
                    </div>
                    <div>
                        <h2>Message for the Support Team</h2>
                        <textarea
                            rows="10"
                            cols="40"
                            value={text}
                            onChange={handleTextChange}
                            placeholder="Write your text here..."
                        ></textarea>
                    </div>
                    <button className={"bg-[#133a8f] p-2 text-white rounded-md"} onClick={handleSubmit}>Submit</button>
            </div>}
            {isSubmitted && <div style={{color:"green"}}>
                You have submitted a message, we will get back to you shortly!
            </div>}
        </div>
    );
};




export default SupportView;