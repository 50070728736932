import AccountTable from "../../../Modules/Components/AccountConfiguration/AccountTable";
import {useNavigate} from "react-router-dom";

const AccountConfiguration = () => {
    const navigate = useNavigate();

    const onAddClicked=()=>{
        navigate("/addaccount")
    }
    return (
        <div>
        <div className="pt-10 flex justify-center items-center">
            <AccountTable/>
        </div>
        <div>
            <button className={"bg-[#133a8f] p-2 text-white rounded-md"} onClick={onAddClicked}>Add Account</button>
        </div>
        </div>
    );
};



export default AccountConfiguration;
